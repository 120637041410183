import {inject, bindable, customElement} from 'aurelia-framework';
import Backend from "../../backend";

@customElement('sub-product-item-img-component')
@inject(Element, Backend)
export class SubProductItemImgComponent {
  element;

  @bindable product;
  @bindable imgClasses = '';
  @bindable showFeaturedBanner = true;
  @bindable showProductDetailsInModal;

  constructor(element, backend) {
    this.element = element;
    this.backend = backend;
  }

  showProductDetails(product) {
    this.element.dispatchEvent(new CustomEvent('click', {
      detail: { product: this.product},
      bubbles: true
    }));
  }
}
