import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, observable, customElement, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SpecialPriceCalculator} from "./specialPriceCalculator";

@customElement('customizable-content-component')
@inject(Element, Backend, ShoppingCart, Router, SpecialPriceCalculator)
export class CustomizableContentComponent {
  element;
  backend;
  shoppingCart;
  router;
  specialPriceCalculator;

  @observable({changeHandler: 'valueOfGoodsChanged'})
  valueOfGoods;

  @observable({changeHandler: 'chosenProductsChanged'})
  chosenProducts = [];

  calculatedPrice;
  initValues = {};

  @bindable() screenMode = true;

  constructor(element, backend, shoppingCart, router, specialPriceCalculator) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.specialPriceCalculator = specialPriceCalculator;
  }

  attached() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__customizable_content__section1"}
        ]})
      .then(result => {
        this.cmsFragments = result;
      });
  }

  // Switched from attached() to bind() after having issues with loading initValues after going back and forth between checkout steps.
  bind() {
    // Get shopping cart info
    let info = this.shoppingCart.getInfo();
    this.crateName = info.crateProduct.name;

    // Put each in the init values
    this.initValues = {};
    info.customizationProducts.forEach(cartSubProduct => {
      this.initValues[cartSubProduct.productId] =
        {
          quantity: cartSubProduct.quantity,
          everyTime: !cartSubProduct.oneTime
        };
    })
  }

  valueOfGoodsChanged() {
    // Min price
    this.calculatedPrice = this.specialPriceCalculator.calculateSumIncVat(this.shoppingCart.getInfo().crateProduct.productId, this.valueOfGoods);
    this.diffToMinPrice = this.specialPriceCalculator.calculateMinPriceDiffIncVat(this.shoppingCart.getInfo().crateProduct.productId, this.valueOfGoods);
  }

  next() {
    // Store in shopping cart
    this.shoppingCart.clearWeekExtraAndCustomizationProducts();
    this.chosenProducts
      // The SubProductComponent allows quantity zero if initValues was non zero because it should be possible to remove items,
      // but that is not necessary here since we are working with client-side data and we just cleared the product list.
      .filter(product => product.quantity > 0)
      .forEach(product => this.shoppingCart.addCustomizationProduct(product.productId, product.quantity, false));

    // Signal that this step is complete.
    this.element.dispatchEvent(new CustomEvent('checkout-flow-next', {
      detail: {},
      bubbles: true
    }));
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }

  chosenProductsChanged() {
    this.addChosenProductsToCart();
  }
  addChosenProductsToCart() {
    // Store in shopping cart
    let self = this;
    this.shoppingCart.atomic(cart => {
      cart.clearWeekExtraAndCustomizationProducts();
      self.chosenProducts
        // The SubProductComponent allows quantity zero if initValues was non zero because it should be possible to remove items,
        // but that is not necessary here since we are working with client-side data and we just cleared the product list.
        .filter(product => product.quantity > 0)
        .forEach(product => {
            cart.addCustomizationProduct(product.productId, product.quantity, !product.everyTime);
        });
      });
  }
}
